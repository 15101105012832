<template>
    <div class="parental-info mt-20">
        <!-- 主申人父母信息（包含已故） -->
        <Container title="主申人父母信息（包含已故）">
            <div :class="[i===1?'warp':'']" v-for="(item, i) in ruleForm" :key="i">
                <el-form :model="ruleForm[i]" :ref="'ruleForm'+i" label-width="180px">
                    <el-row>
                        <el-col :span="12">
                            <el-row :gutter="10">
                                <el-col :span="20">
                                    <el-form-item
                                        :label="i===0 ? '父亲姓名：': '母亲姓名：'"
                                        prop="subset_name"
                                        :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }"
                                    >
                                        <el-input v-model="item.subset_name" clearable maxlength="50" placeholder="请输入对应的内容" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item class="form-item">
                                        <el-checkbox v-model="item.deceased">已故</el-checkbox>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>

                        <template v-if="item.deceased">
                            <el-col :span="24">
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item
                                            label="出生日期："
                                            prop="birthday"
                                            :rules="{ required: true, message: '请选择出生日期', trigger: 'change' }"
                                        >
                                            <el-date-picker
                                                style="width: 100%;"
                                                v-model="item.birthday"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                                placeholder="年 -月 -日">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="12">
                                        <el-row>
                                            <el-col :span="17">
                                                <el-form-item
                                                    label="出生城市："
                                                    prop="birth_place_aboard"
                                                    :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                                                >
                                                    <el-radio-group v-model="item.birth_place_aboard" @change="handleSelect($event, i)">
                                                        <el-radio :label="0">国内</el-radio>
                                                        <el-radio :label="1">国外</el-radio>
                                                    </el-radio-group>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="7" v-if="item.birth_place_aboard === 0">
                                                <el-form-item
                                                    class="form-item"
                                                    prop="birth_place.area"
                                                    :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                                                >
                                                    <CitySelect v-model="item.birth_place.area"  style="width: 100%;" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="7" v-if="item.birth_place_aboard === 1">
                                                <el-form-item
                                                    class="form-item"
                                                    prop="birth_place.foreign"
                                                    :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                                                >
                                                    <el-input v-model="item.birth_place.foreign" clearable maxlength="50" placeholder="请输入城市" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </template>

                        <template v-if="!item.deceased">
                            <el-col :span="12">
                                <el-form-item label="婚姻状况：" prop="married" :rules="{ required: true, message: '请选择婚姻状况', trigger: 'change' }">
                                    <el-select v-model="item.married" placeholder="请选择" clearable style="width: 100%;">
                                        <el-option v-for="(item, index) in $CONFIG.MARRIED" :key="index" :label="item.label" :value="item.value+''" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="出生日期："
                                    prop="birthday"
                                    :rules="{ required: true, message: '请选择出生日期', trigger: 'change' }"
                                >
                                    <el-date-picker
                                        style="width: 100%;"
                                        v-model="item.birthday"
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions"
                                        placeholder="年 -月 -日">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-row>
                                    <el-col :span="17">
                                        <el-form-item
                                            label="出生城市："
                                            prop="birth_place_aboard"
                                            :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                                        >
                                            <el-radio-group v-model="item.birth_place_aboard" @change="handleSelect($event, i)">
                                                <el-radio :label="0">国内</el-radio>
                                                <el-radio :label="1">国外</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="7" v-if="item.birth_place_aboard === 0">
                                        <el-form-item
                                            class="form-item"
                                            prop="birth_place.area"
                                            :rules="{ required: true, message: '请选择出生城市', trigger: 'change' }"
                                        >
                                            <CitySelect v-model="item.birth_place.area"  style="width: 100%;" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="7" v-if="item.birth_place_aboard === 1">
                                        <el-form-item
                                            class="form-item"
                                            prop="birth_place.foreign"
                                            :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                                        >
                                            <el-input v-model="item.birth_place.foreign" clearable maxlength="50" placeholder="请输入城市" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="职业："
                                    prop="occupation"
                                    :rules="{ required: true, message: '请选择职业', trigger: 'change' }"
                                >
                                    <el-select v-model="item.occupation" clearable placeholder="请选择" style="width: 100%;" @change="handleOctChange($event, i)">
                                        <el-option
                                            v-for="(item, i) in workOpts"
                                            :key="i"
                                            :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    v-if="item.occupation === '在职'"
                                    label="职业名称："
                                    prop="occupation_name"
                                    :rules="{ required: true, message: '请填写职业名称', trigger: 'blur' }"
                                >
                                    <el-input v-model="item.occupation_name" clearable maxlength="20" placeholder="如在职，请填写目前的岗位名称" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="现居住国家/地区："
                                    prop="live_country"
                                    :rules="{ required: true, message: '请填写现居住国家/地区', trigger: 'blur' }"
                                >
                                    <CountrySelect v-model="item.live_country" placeholder="输入匹配国家" style="width: 100%" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-row>
                                    <el-col :span="12">
                                        <el-form-item
                                            label="是否办理过香港身份证："
                                            prop="child_has_hk_id.has"
                                            :rules="{ required: true, message: '请选择是否办理过香港身份证', trigger: 'change' }"
                                        >
                                            <el-radio-group v-model="item.child_has_hk_id.has" @change="handleChang($event, i)">
                                                <el-radio label="Y">是</el-radio>
                                                <el-radio label="N">否</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item
                                            v-if="item.child_has_hk_id.has === 'Y'"
                                            label="香港身份证号码："
                                            prop="hk_identity_card"
                                            :rules="[{ required: true, message: '请输入香港身份证号码', trigger: 'blur' },
                                            { required: true, trigger: 'blur', validator: $rules.validate.hkmId }]">
                                            <el-input v-model.trim="item.hk_identity_card" clearable maxlength="50" placeholder="请输入香港身份证号码" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </template>
                    </el-row>
                </el-form>
            </div>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        dataFather: {
            type: Object,
            default: () => null
        },
        dataMother: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            ruleForm: [
                {
                    relation: 1,
                    deceased: 0,
                    subset_name: '',
                    married: '',
                    used_name: '',
                    birthday: '',
                    birth_place_aboard: 0,
                    birth_place: {
                        area: [],
                        country: '',
                        details: '',
                        foreign: ''
                    },
                    occupation: '',
                    occupation_name: '',
                    child_has_hk_id: {
                        has: ''
                    },
                    hk_identity_card: '',
                    country_option: ''
                },
                {
                    relation: 2,
                    deceased: 0,
                    married: '',
                    subset_name: '',
                    used_name: '',
                    birthday: '',
                    birth_place_aboard: 0,
                    birth_place: {
                        area: [],
                        country: '',
                        details: '',
                        foreign: ''
                    },
                    occupation: '',
                    occupation_name: '',
                    child_has_hk_id: {
                        has: ''
                    },
                    hk_identity_card: '',
                    country_option: ''
                }
            ],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            workOpts: ["在职", "退休", "无职业"]
        }
    },
    created() {
        if (this.dataFather) this.ruleForm[0] = this.dataFather
        if (this.dataMother) this.ruleForm[1] = this.dataMother
        this.ruleForm.forEach(item => {
            for(let key in item) {
                if (item[key] == 100) {
                    item[key] = ''
                }
            }
        })

    },
    methods: {
        // 校验参数
        checkParm() {
            const ruleFormArray = []
            this.ruleForm.forEach((item, i) => {
                ruleFormArray.push(this.$refs['ruleForm'+i][0].validate())
            })
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        // 是否办理过香港身份证
        handleChang(v, index) {
            this.ruleForm[index].hk_identity_card = ''
        },
        // 出生城市选择
        handleSelect(v, index) {
            if (v===0) {
                this.ruleForm[index].birth_place.foreign = ''
            } else {
                this.ruleForm[index].birth_place.area = []
            }
        },
        // 是否在职
        handleOctChange(v, index) {
            if (v !== '在职') {
                this.ruleForm[index].occupation_name = ''
            }
        }
    },
}
</script>

<style lang="less" scoped>
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
.warp {
    border-top: 1px solid #eee;
    padding-top: 20px;
}
</style>
